import React from "react"
import Meta from "../components/meta"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Title from "../components/title"
import Back from "../components/back"

export default function Home() {
  return (
    <>
      <Meta title="RPA を利用した業務自動化" description="RPA は万能なツールではありませんが、適材適所の導入により大きな成果が期待できます。まずはご相談ください。" />
      <div className="flex flex-col h-screen">
        <header>
          <Navbar hasContact={false} />
        </header>
        <main className="mt-14 reveal fade-bottom">
          <div className="container max-w-5xl mx-auto py-4 px-4">
            <Title title="RPA を利用した業務自動化" />

            <div className="bg-white rounded-lg mb-4 flex flex-wrap">
              <div className="w-full p-6">
                <h3 className="card-heading text-xl md:text-3xl font-bold leading-none">
                  RPA とは？
                </h3>
                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  RPA (Robotic Process Automation) とは日常的なホワイトカラー業務の効率化の取り組みです。
                </p>

                <ul className="mt-4 md:mt-8">
                  <li>メールに添付された見積書 Excel ファイルをダウンロード</li>
                  <li>見積書ファイルを開いて見積金額をコピー</li>
                  <li>自社システムへ金額を転記入力</li>
                  <li>関係者へのメール通達</li>
                </ul>

                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  このような日常的な動作を自動化することができます。Excel のマクロをご存知の方は、パソコンの操作全体に対するマクロというようなイメージをしていただいても良いかもしれません。
                  また上記の例で金額の大小や特定の取引先の場合は追加のアクションを行うということも、もちろん可能です。
                  どのような企業でも存在する経理や人事業務のみならず、パソコンを使った一定のルールで繰り返し行われる作業全般が対象となります。
                  <br />
                  将来的には AI とともに、より高度な経営判断を助けるものへの進化が予想されますが、現在は人間の介在による確認ステップと組み合わせての利用シーンも多いようです。
                </p>

                <h3 className="mt-4 md:mt-8 card-heading text-xl md:text-3xl font-bold leading-none">
                  RPA 導入のメリット
                </h3>
                <div className="mt-4 md:mt-8 text-gray-600 leading-7">
                  <span className="text-lg text-marker-light">業務のスピードアップ</span><br />
                  <p className="mb-2 mx-4">ソフトウェアのため、人間のように休憩も必要なくいつでも動作させることができます。</p>

                  <span className="text-lg text-marker-light">正確性の向上</span><br />
                  <p className="mb-2 mx-4">単純作業でも人為的なミスはゼロにはできませんが、ソフトウェアは適切な条件を与えてやることで気分などのムラなく作業を完遂することができます。</p>

                  <span className="text-lg text-marker-light">適切なリソース配分が可能に</span><br />
                  <p className="mb-2 mx-4">より高度な判断を必要とする人間ならではの業務へリソース集中ができます。今後の労働人口の減少に対しての有効性も言うまでもありません。</p>

                  <span className="text-lg text-marker-light">既存システムへの影響が小さい</span><br />
                  <p className="mx-4">効率化のためのシステム導入や入れ替えなど複雑でリスクの高い作業を伴わずに、現在の仕組みを利用した自動化を検討できます。ごく一部のみ実験的に導入なども容易です。</p>
                </div>

                <h3 className="mt-4 md:mt-8 card-heading text-xl md:text-3xl font-bold leading-none">
                  当社の取り組み
                </h3>
                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  世界で 1000 社以上の導入実績があるというリーディングカンパニーである UiPath 社のプラットホームを利用して RPA 開発の基本であるトレーニングを完了し、ビジネスプロセスの自動化に精通していることを示す RPA Foundation Training 修了証を取得しています。
                  <br />
                  RPA は万能なツールではありませんが、適材適所の導入により大きな成果が期待できます。まずはご相談ください。
                </p>

                <Back />
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}
